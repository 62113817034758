import {Component, OnInit} from '@angular/core';
import {AuthorizationService} from './shared/service/authorization.service';
import {Router} from '@angular/router';
import {Login} from './shared/model/login.model';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FacadeService} from '../shared/service/facade/facade.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  login: Login;
  lang : string;

  constructor(private router: Router,
              private authorizationService: AuthorizationService,
              private facadeService: FacadeService,
              private snackBar: MatSnackBar,
              private translate: TranslateService) {
    this.login = new Login();
  }

  ngOnInit() {
    this.lang = navigator.language;
    if (this.authorizationService.loggedIn()) {
      this.router.navigateByUrl('/dashboard').then();
    }
  }

  onSubmit() {
    this.facadeService.authorize(this.login).subscribe({
      next: res => {
        const token = res.headers.get('authorization');
        const refreshToken = res.headers.get('refresh_token');
        this.authorizationService.setUser(token, refreshToken, this.login.rememberMe).then();
      },
      error: ignoreProperty => {
        this.snackBar.open(this.translate.instant('login.message.error.login'), 'X', {
          duration: 10000,
          panelClass: ['customSnackError']
        });
      }
    });
  }
}
