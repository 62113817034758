export class Sector {
  id: number;
  active: boolean;
  code: string;
  nameDE: string;
  nameFR: string;
  nameNL: string;
  nameEN: string;
  nameTranslated: string;
}
