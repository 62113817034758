import {Component, OnInit, ViewChild, SimpleChanges} from '@angular/core';
import {Invoice} from './shared/model/invoice.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {FacadeService} from '../shared/service/facade/facade.service';
import {MatDialog} from '@angular/material/dialog';
import {ExportDialogComponent, ExportDialogParam} from '../shared/component/export-dialog/export-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {InvoicePage} from '../shared/model/pagination/billing/invoice-page.model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-bills',
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.css']
})
export class BillsComponent implements OnInit {
  @ViewChild('billsPaginator', {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['invoiceId', 'customerName', 'costCenter', 'totalVATExcluded', 'detail'];
  dataSource = new MatTableDataSource<Invoice>();
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  length: number;

  searchForm: UntypedFormGroup;
  query: string;

  constructor(private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private facadeService: FacadeService,
              private snackBar: MatSnackBar,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.getAllInvoices();
    this.query = this.route.snapshot.queryParams['search'];
    this.searchForm = this.formBuilder.group({
      query: [this.query, []],
    });

    this.searchForm.controls["query"].valueChanges.subscribe(value => {
      if (value && value.length >= 3) {
        this.query = value;
        this.pageIndex = 0;
      } else {
        this.query = null;
      }
      this.getEventBillsData();
    });
  }

  handleChangePagination(event?: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getEventBillsData();
    return event;
  }

  exportInvoice() {
    const param = new ExportDialogParam(this.snackBar, this.translate);
    param.invoicesExport = true;
    const dialogRef = this.dialog.open(ExportDialogComponent, {data: param});
    dialogRef.afterClosed().subscribe(
      result => param.saveFile(this.facadeService.getBillingToExport(
        result.prestationStartDateFrom,
        result.prestationStartDateTo,
        result.prestationEndDateFrom,
        result.prestationEndDateTo,
        result.invoiceCreationDateFrom,
        result.invoiceCreationDateTo,
        result.invoiceDueDateFrom,
        result.invoiceDueDateTo,
        result.operatingUnitIds,
        result.customerId

        )));
  }

  exportInvoiceExactOnline() {
    const param = new ExportDialogParam(this.snackBar, this.translate);
    param.invoicesExportExactOnline = true;
    const dialogRef = this.dialog.open(ExportDialogComponent, {data: param});
    dialogRef.afterClosed().subscribe(
      result => param.saveFile(this.facadeService.getBillingToExportExactOnline(
        result.invoiceCreationFrom,
        result.invoiceCreationTo,
        result.operatingUnitIds,
        result.customerId)));
  }

  private getAllInvoices() {
    this.pageIndex = this.route.snapshot.queryParams['pageIndex'] ?? 0;
    this.pageSize = this.route.snapshot.queryParams['pageSize'] ?? 20;
    const invoicePage: InvoicePage = this.route.snapshot.data['invoices'];
    this.length = invoicePage.totalElements;
    this.dataSource = new MatTableDataSource<Invoice>(invoicePage.content);
    this.dataSource.paginator = this.paginator;
  }

  getEventBillsData() {
    this.facadeService.getPageBills(this.pageIndex, this.pageSize, this.query).subscribe(
      (res: InvoicePage) => {
        this.dataSource.data = res.content;
        this.length = res.totalElements;
      }
    );
  }
}
