import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {WorkerPageSerializer} from '../../model/pagination/worker/worker-page.serializer';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {WorkerPage} from '../../model/pagination/worker/worker-page.model';
import {CustomerPageSerializer} from '../../model/pagination/customer/customer-page.serializer';
import {OperatingUnitPageSerializer} from '../../model/pagination/operating-unit/operating-unit-page.serializer';
import {CustomerPage} from '../../model/pagination/customer/customer-page.model';
import {OperatingUnitPage} from '../../model/pagination/operating-unit/operating-unit-page.model';
import {RequestPageSerializer} from '../../model/pagination/request/request-page.serializer';
import {ContractPageSerializer} from '../../model/pagination/contract/contract-page.serializer';
import {RequestPage} from '../../model/pagination/request/request-page.model';
import {ContractPage} from '../../model/pagination/contract/contract-page.model';
import { InvoicePage } from '../../model/pagination/billing/invoice-page.model';
import { InvoicePageSerializer } from '../../model/pagination/billing/invoice-page.serializer';

@Injectable({
  providedIn: 'root'
})
export class ElasticSearchService {

  private workerElasticSearchURL = environment.elastic_search_URL + 'workers/';
  private customerElasticSearchURL = environment.elastic_search_URL + 'customers/';
  private operatingUnitElasticSearchURL = environment.elastic_search_URL + 'operating-units/';
  private requestElasticSearchURL = environment.elastic_search_URL + 'requests/';
  private contractElasticSearchURL = environment.elastic_search_URL + 'contracts/';
  private invoiceElasticSearchURL = environment.elastic_search_URL + 'invoices/';

  private workerPageSerializer = new WorkerPageSerializer();
  private customerPageSerializer = new CustomerPageSerializer();
  private operatingUnitPageSerializer = new OperatingUnitPageSerializer();
  private requestPageSerializer = new RequestPageSerializer();
  private contractPageSerializer = new ContractPageSerializer();
  private invoicePageSerializer = new InvoicePageSerializer();

  constructor(private http: HttpClient) {
  }

  filterContracts(index: number, size: number, query: string) {
    const params = this.createParams(query, index, size);

    return this.http.get<ContractPage>(this.contractElasticSearchURL + 'search', {params: params}).pipe(
      map((data: ContractPage) => this.contractPageSerializer.fromJson(data))
    );
  }

  filterBills(index: number, size: number, query: string) {
    const params = this.createParams(query, index, size);

    return this.http.get<InvoicePage>(this.invoiceElasticSearchURL + 'search', {params: params}).pipe(
      map((data: InvoicePage) => this.invoicePageSerializer.fromJson(data))
    );
  }

  filterWorker(query: string, index: number, size: number) {
    const params = this.createParams(query, index, size);

    return this.http.get<WorkerPage>(this.workerElasticSearchURL + 'search', {params: params}).pipe(
      map((data: WorkerPage) => this.workerPageSerializer.fromJson(data))
    );
  }

  filterCustomers(query: string, index: number, size: number) {
    const params = this.createParams(query, index, size);

    return this.http.get<CustomerPage>(this.customerElasticSearchURL + 'search', {params: params}).pipe(
      map((data: CustomerPage) => this.customerPageSerializer.fromJson(data))
    );
  }

  filterOperatingUnits(query: string, index: number, size: number) {
    const params = this.createParams(query, index, size);

    return this.http.get<OperatingUnitPage>(this.operatingUnitElasticSearchURL + 'search', {params: params}).pipe(
      map((data: OperatingUnitPage) => this.operatingUnitPageSerializer.fromJson(data))
    );
  }

  filterRequests(query: string, index: number, size: number) {
    const params = this.createParams(query, index, size);

    return this.http.get<RequestPage>(this.requestElasticSearchURL + 'search', {params: params}).pipe(
      map((data: RequestPage) => this.requestPageSerializer.fromJson(data))
    );
  }

  private createParams(query: string, index: number, size: number) {
    return {
      query: query,
      index: index,
      size: size
    };
  }
}
