import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FacadeService } from '../../../shared/service/facade/facade.service';
import { MatDialog } from '@angular/material/dialog';
import { JointCommission } from '../../../shared/model/joint-commission/joint-commission.model';
import { JointCommissionPage } from 'src/app/shared/model/pagination/joint-commission/joint-commission-page.model';
import { Sector } from '../../../shared/model/sector/sector.model';
import { JointCommissionDialogComponent } from 'src/app/shared/component/admin-dialog/joint-commission-dialog/joint-commission-dialog.component';


@Component({
  selector: 'app-joint-commission',
  templateUrl: './joint-commission.component.html',
  styleUrls: ['./joint-commission.component.css', '../../admin.component.css']
})
export class JointCommissionComponent implements OnInit {

  @ViewChild('JointCommissionPaginator', { static: true }) paginator: MatPaginator;

  jointCommission: JointCommission;
  sectorList: Sector[] = [];

  dataSource = new MatTableDataSource<JointCommission>();
  pageEvent: PageEvent;
  pageIndex: number = 1;
  pageSize: number = 20;
  length: number;

  constructor(
    private facadeService: FacadeService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getAllSectors();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  displayedColumns: string[] = [
    "id",
    "active",
    "code",
    "nameDE",
    "nameEN",
    "nameFR",
    "nameNL",
    "sector"
  ];

  public getData(event?: PageEvent){
    this.pageIndex = event ? event.pageIndex : 0;
    this.pageSize = event ? event.pageSize : 20;
    this.facadeService.getPageJointCommission(this.pageIndex, this.pageSize).subscribe(
      (data: JointCommissionPage) => {
        this.dataSource.data = data.content;
        this.dataSource.paginator = this.paginator;
        this.length = data.totalElements;
      }
    );
    return event;
  }

  getAllSectors() {
      this.facadeService.listSectors().subscribe((list: Sector[]) => {
          this.sectorList = list;
          this.getData();
      });
  }

  setSector(element: JointCommission) {
    for (let i = 0; i < this.sectorList.length; i++) {
        if (this.sectorList[i].id === element.sector.id) {
            element.sector = this.sectorList[i];
            break;
        }
    }
  }

  openPopUp(row) {
    const dialogRef = this.dialog.open(JointCommissionDialogComponent, {
      data: { element: row },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllSectors();
    });
  }

  addElement() {
    this.jointCommission = new JointCommission;
    this.openPopUp(this.jointCommission);
  }

}
