import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FacadeService } from "src/app/shared/service/facade/facade.service";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Contract } from "src/app/contracts/shared/model/contract.model";

@Component({
  selector: "app-delete-contract-dialog",
  templateUrl: "./delete-contract-dialog.component.html",
  styleUrls: ["./delete-contract-dialog.component.css"],
})
export class DeleteContractDialogComponent implements OnInit {

  contract: Contract;

  constructor(
    private dialogRef: MatDialogRef<Component>,
    private facade: FacadeService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.contract = this.data.element;
  }

  ngOnInit(): void {}

  onSubmit() {
    this.facade.deleteContract(this.contract).subscribe({
      next: res => {
        this.openSnackBar(this.translate.instant('contracts.delete.success-message'), 'X', 'customSnackValid');
        // refresh page
        window.location.reload();
      },
      error: error => {
        this.openSnackBar(this.translate.instant('contracts.delete.failed-message'), 'X', 'customSnackError');
      }
    });

    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string, pC: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC],
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
