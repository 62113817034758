import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CustomersComponent} from './customers/customers.component';
import {CustomerNewComponent} from './customers/customer-new/customer-new.component';
import {LoginComponent} from './login/login.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {CustomerEditComponent} from './customers/customer-edit/customer-edit.component';
import {CustomerViewComponent} from './customers/customer-view/customer-view.component';
import {OperatingUnitsComponent} from './operating-units/operating-units.component';
import {OperatingUnitNewComponent} from './operating-units/operating-unit-new/operating-unit-new.component';
import {OperatingUnitEditComponent} from './operating-units/operating-unit-edit/operating-unit-edit.component';
import {WorkerViewComponent} from './workers/worker-view/worker-view.component';
import {WorkerEditComponent} from './workers/worker-edit/worker-edit.component';
import {WorkerNewComponent} from './workers/worker-new/worker-new.component';
import {WorkersComponent} from './workers/workers.component';
import {RequestsComponent} from './requests/requests.component';
import {RequestEditComponent} from './requests/request-edit/request-edit.component';
import {RequestNewComponent} from './requests/request-new/request-new.component';
import {PlanningComponent} from './planning/planning.component';
import {UsersComponent} from './users/users.component';
import {CustomersResolver} from './shared/resolver/customer/customers.resolver';
import {WorkersResolver} from './shared/resolver/worker/workers.resolver';
import {UserEditComponent} from './users/user-edit/user-edit.component';
import {UserNewComponent} from './users/user-new/user-new.component';
import {CustomerResolver} from './shared/resolver/customer/customer.resolver';
import {OperatingUnitResolver} from './shared/resolver/operating-unit/operating-unit.resolver';
import {RequestResolver} from './shared/resolver/request/request.resolver';
import {WorkerResolver} from './shared/resolver/worker/worker.resolver';
import {UsersConsultantResolver} from './shared/resolver/user/users-consultant.resolver';
import {ContractsComponent} from './contracts/contracts.component';
import {PrestationsComponent} from './prestations/prestations.component';
import {WorkersActiveResolver} from './shared/resolver/worker/workers-active.resolver';
import {CustomersActiveResolver} from './shared/resolver/customer/customers-active.resolver';
import {NaceCodesResolver} from './shared/resolver/reference-data/nace-codes.resolver';
import {DashboardComponent} from './dashboard/dashboard.component';
import {RequestErrorDetailComponent} from './requests/shared/component/request-error-detail/request-error-detail.component';
import {DeadlineRequestsResolver} from './shared/resolver/request/deadline-requests.resolver';
import {PrestationsToValidateResolver} from './shared/resolver/prestation/prestations-to-validate.resolver';
import {ContractResolver} from './shared/resolver/contract/contract.resolver';
import {BillsComponent} from './bills/bills.component';
import {BillViewComponent} from './bills/bill-view/bill-view.component';
import {ContractViewComponent} from './contracts/contract-view/contract-view.component';
import {CountriesResolver} from './shared/resolver/country/countries.resolver';
import {LanguagesResolver} from './shared/resolver/language/languages.resolver';
import {WorkerDocumentResolver} from './shared/resolver/document/worker-documents.resolver';
import {WorkerPendingDocumentResolver} from './shared/resolver/document/worker-pending-documents.resolver';
import {WorkersLiteComponent} from './workers-lite/workers-lite.component';
import {ActiveWorkersLiteResolver} from './shared/resolver/worker-lite/active-workers-lite-resolver.service';
import {WorkerLiteResolver} from './shared/resolver/worker-lite/worker-lite.resolver';
import {InvoicesResolver} from './shared/resolver/invoices/invoices.resolver';
import {InvoiceResolver} from './shared/resolver/invoices/invoice.resolver';
import {SectorsResolver} from './shared/resolver/sector/sectors.resolver';
import {ErrorRequestsResolver} from './shared/resolver/request/error-requests.resolver';
import {CompanyPrefixesResolver} from './shared/resolver/reference-data/company-prefixes.resolver';
import {SupervisorGuard} from './login/shared/guard/supervisor-guard.service';
import {WorkersPageResolver} from './shared/resolver/worker/workers-page.resolver';
import {CustomersPageResolver} from './shared/resolver/customer/customers-page.resolver';
import {PendingRequestsResolver} from './shared/resolver/request/pending-requests.resolver';
import {OperatingUnitsPageResolver} from './shared/resolver/operating-unit/operating-units-page.resolver';
import {RequestResetPasswordComponent} from './reset-password/request-reset-password/request-reset-password.component';
import {EditPasswordComponent} from './reset-password/edit-password/edit-password.component';
import {UserUuidResolver} from './shared/resolver/user/user-uuid.resolver';
import {AdminComponent} from './admin/admin.component';
import {PrestationsReleasedResolver} from './shared/resolver/prestation/prestations-released-resolver';
import {PrestationsNotReleasedResolver} from './shared/resolver/prestation/prestations-not-released.resolver';
import {CustomerDocumentResolver} from './shared/resolver/document/customer-documents.resolver';
import {AuthGuard} from './login/shared/guard/auth-guard.service';
import {WorkerPositionsResolver} from './shared/resolver/reference-data/worker-positions.resolver';
import {WorkerFromRequestResolver} from './shared/resolver/request/worker-from-request.resolver';
import {CustomerFromRequestResolver} from './shared/resolver/request/customer-from-request.resolver';
import {RequestsPageResolver} from './shared/resolver/request/requests-page.resolver';
import {ActiveOperatingUnitsResolver} from './shared/resolver/operating-unit/active-operating-units.resolver';
import {CustomerRequestsResolver} from './shared/resolver/request/customer-requests.resolver';
import {ContractsPageResolver} from './shared/resolver/contract/contracts-page.resolver';
import {ContractsFromRequestResolver} from './shared/resolver/request/contracts-from-request.resolver';
import {PrestationsCorrectionResolver} from './shared/resolver/request/prestation-correction-from-request.resolver';
import {PrestationsValidationFromRequestResolver} from './shared/resolver/request/prestations-validation-from-request.resolver';
import {UsersWorkerResolver} from './shared/resolver/user/users-worker.resolver';
import {UsersCustomerResolver} from './shared/resolver/user/users-customer.resolver';
import { SuperAdminGuard } from './login/shared/guard/super-admin-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'send-password-reset',
    component: RequestResetPasswordComponent
  },
  {
    path: 'reset-password',
    component: EditPasswordComponent,
    resolve: {
      user: UserUuidResolver
    }
  },
  {
    path: 'contracts',
    canActivate: [AuthGuard],
    component: ContractsComponent,
    resolve: {
      contracts: ContractsPageResolver
    }
  },
  {
    path: 'contract-view',
    canActivate: [AuthGuard],
    component: ContractViewComponent,
    resolve: {
      contract: ContractResolver
    }
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, SuperAdminGuard],
    component: AdminComponent
  },
  {
    path: 'bills',
    canActivate: [AuthGuard],
    component: BillsComponent,
    resolve: {
      invoices: InvoicesResolver
    }
  },
  {
    path: 'bill-view',
    canActivate: [AuthGuard],
    component: BillViewComponent,
    resolve: {
      invoice: InvoiceResolver
    }
  },
  {
    path: 'contract-view',
    canActivate: [AuthGuard],
    component: ContractViewComponent,
    resolve: {
      contract: ContractResolver,
    }
  },
  {
    path: 'customers',
    canActivate: [AuthGuard],
    component: CustomersComponent,
    resolve: {
      customers: CustomersPageResolver
    }
  },
  {
    path: 'customer-edit',
    canActivate: [AuthGuard],
    component: CustomerEditComponent,
    resolve: {
      customer: CustomerResolver,
      naceCodes: NaceCodesResolver,
      companyPrefixes: CompanyPrefixesResolver,
      countries: CountriesResolver,
      operatingUnits: ActiveOperatingUnitsResolver
    }
  },
  {
    path: 'customer-new',
    canActivate: [AuthGuard],
    component: CustomerNewComponent,
    resolve: {
      naceCodes: NaceCodesResolver,
      companyPrefixes: CompanyPrefixesResolver,
      countries: CountriesResolver,
      operatingUnits: ActiveOperatingUnitsResolver
    }
  },
  {
    path: 'customer-view',
    canActivate: [AuthGuard],
    component: CustomerViewComponent,
    resolve: {
      customer: CustomerResolver,
      documents: CustomerDocumentResolver,
      companyPrefixes: CompanyPrefixesResolver,
      requests: CustomerRequestsResolver,
    }
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    resolve: {
      deadlineRequests: DeadlineRequestsResolver,
      prestationsToValidate: PrestationsToValidateResolver,
      errorRequests: ErrorRequestsResolver
    }
  },
  {
    path: 'operating-units',
    canActivate: [SupervisorGuard, AuthGuard],
    component: OperatingUnitsComponent,
    resolve: {
      operatingUnits: OperatingUnitsPageResolver
    }
  },
  {
    path: 'operating-unit-edit',
    component: OperatingUnitEditComponent,
    canActivate: [SupervisorGuard, AuthGuard],
    resolve: {
      operatingUnit: OperatingUnitResolver,
      sectors: SectorsResolver
    }
  },
  {
    path: 'operating-unit-new',
    canActivate: [SupervisorGuard, AuthGuard],
    component: OperatingUnitNewComponent,
    resolve: {
      sectors: SectorsResolver
    }
  },
  {
    path: 'planning',
    canActivate: [AuthGuard],
    component: PlanningComponent,
    resolve: {
      operatingUnits: ActiveOperatingUnitsResolver,
      customers: CustomersActiveResolver,
      pendingRequests: PendingRequestsResolver,
      prestationsReleased: PrestationsReleasedResolver
    }
  },
  {
    path: 'prestations',
    canActivate: [AuthGuard],
    component: PrestationsComponent,
    resolve: {
      prestations: PrestationsNotReleasedResolver,
    }
  },
  {
    path: 'requests',
    canActivate: [AuthGuard],
    component: RequestsComponent,
    resolve: {
      errorRequests: ErrorRequestsResolver,
      requests: RequestsPageResolver
    }
  },
  {
    path: 'request-edit',
    canActivate: [AuthGuard],
    component: RequestEditComponent,
    resolve: {
      request: RequestResolver,
      contracts: ContractsFromRequestResolver,
      workers: WorkerFromRequestResolver,
      customers: CustomerFromRequestResolver,
      prestationCorrections: PrestationsCorrectionResolver,
      prestationsToValidate: PrestationsValidationFromRequestResolver
    }
  },
  {
    path: 'request-error-detail',
    canActivate: [AuthGuard],
    component: RequestErrorDetailComponent,
    resolve: {
      request: RequestResolver
    }
  },
  {
    path: 'request-new',
    canActivate: [AuthGuard],
    component: RequestNewComponent,
    resolve: {
      request: RequestResolver,
      customers: CustomersActiveResolver,
      workers: WorkersActiveResolver
    }
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    component: UsersComponent,
    resolve: {
      consultants: UsersConsultantResolver,
      workers: UsersWorkerResolver,
      customers: UsersCustomerResolver
    }
  },
  {
    path: 'user-edit',
    canActivate: [AuthGuard],
    component: UserEditComponent,
    resolve: {
      operatingUnits: ActiveOperatingUnitsResolver
    }
  },
  {
    path: 'user-new',
    canActivate: [AuthGuard],
    component: UserNewComponent,
    resolve: {
      operatingUnits: ActiveOperatingUnitsResolver
    }
  },
  {
    path: 'workers',
    canActivate: [AuthGuard],
    component: WorkersComponent,
    resolve: {
      workers: WorkersPageResolver
    }
  },
  {
    path: 'worker-edit',
    canActivate: [AuthGuard],
    component: WorkerEditComponent,
    resolve: {
      worker: WorkerResolver,
      operatingUnits: ActiveOperatingUnitsResolver,
      countries: CountriesResolver,
      languages: LanguagesResolver,
      workerPositions: WorkerPositionsResolver
    }
  },
  {
    path: 'worker-new',
    canActivate: [AuthGuard],
    component: WorkerNewComponent,
    resolve: {
      operatingUnits: ActiveOperatingUnitsResolver,
      countries: CountriesResolver,
      languages: LanguagesResolver,
      workerLite: WorkerLiteResolver,
      workerPositions: WorkerPositionsResolver
    }
  },
  {
    path: 'worker-view',
    canActivate: [AuthGuard],
    component: WorkerViewComponent,
    resolve: {
      worker: WorkerResolver,
      documents: WorkerDocumentResolver,
      pendingDocuments: WorkerPendingDocumentResolver
    }
  },
  {
    path: 'workers-lite',
    canActivate: [AuthGuard],
    component: WorkersLiteComponent,
    resolve: {
      workersLite: ActiveWorkersLiteResolver
    }
  },
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
