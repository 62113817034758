import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FacadeService } from 'src/app/shared/service/facade/facade.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-validation-dialog',
  templateUrl: './validation-dialog.component.html',
  styleUrls: ['./validation-dialog.component.css']
})
export class ValidationDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<Component>,
    private facade: FacadeService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
  }

  onSubmit(){
    this.facade.generatePtg().subscribe((element) => {
      console.log('generation done')
    });
    this.openSnackBar(this.translate.instant('admin.tab.generation.generation-popup'), 'X', 'customSnackValid');

    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string, pC: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
