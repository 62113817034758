import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FacadeService } from 'src/app/shared/service/facade/facade.service';
import { OperatingUnit } from 'src/app/operating-units/shared/model/operating-unit.model';
import { FamilySituation, Gender, ResidentPermitType, StudentQuotaHour, StudyLevel, Worker, WorkerType } from '../../model/worker.model';
import { convertCountries, Country } from '../../../../shared/model/country/country.model';
import { Language } from '../../../../shared/model/language/language.model';
import { MinimumDateValidators } from '../../../../shared/validator/minimum-date.validator';
import { AgeCheckValidators } from '../../../../shared/validator/ageCheck.validator';
import { BehaviorSubject } from 'rxjs';
import { AuthorizationService } from '../../../../login/shared/service/authorization.service';
import { Role } from '../../../../users/shared/model/user.model';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Bic } from '../../../../shared/model/bic/bic.model';
import { ibanValidator } from 'ngx-iban';
import { emailValidator } from '../../../../shared/validator/email.validator';
import * as moment from 'moment';
import { convertWorkerPosition, WorkerPosition } from '../../../../shared/worker-position/worker-position.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DimonaErrorAction } from '../../../../shared/model/dimona/dimona.model';
import { ConfirmationDialogComponent } from '../../../../shared/component/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-worker-form',
  templateUrl: './worker-form.component.html',
  styleUrls: ['./worker-form.component.css']
})
export class WorkerFormComponent implements OnInit, AfterViewInit {

  static readonly MIN_YEAR_STUDENT = 1980;
  static readonly IS_NURSING = environment.isNursing;
  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  quotaHoursDisplayedColumns: string[] = ['year', 'hoursDone', 'action'];
  quotaHours = new MatTableDataSource<StudentQuotaHour>();
  @ViewChild('paginatorStudentQuotaHours', {static: true}) paginatorQuotaHours: MatPaginator;
  type: WorkerType;
  workerForm: UntypedFormGroup;
  studentQuotaHoursArray: UntypedFormArray = this.formBuilder.array([]);
  @Input() worker: Worker;
  @Input() operatingUnits: OperatingUnit[];
  @Input() countries: Country[];
  @Input() languages: Language[];
  @Input() workerPositions: WorkerPosition[];
  familySituations = Object.keys(FamilySituation);
  genders = Object.keys(Gender);
  workerTypes = Object.keys(WorkerType);
  residentPermitTypes = Object.keys(ResidentPermitType);
  studyLevels = Object.keys(StudyLevel);
  workerLiteId: number;
  clicked = false;
  force = false;
  isAdmin = false;

  constructor(private formBuilder: UntypedFormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private facadeService: FacadeService,
              private snackBar: MatSnackBar,
              private authorizationService: AuthorizationService,
              private translate: TranslateService,
              private dialog: MatDialog) {
    this.workerLiteId = +this.route.snapshot.queryParamMap.get('wL');
  }

  get staticIsNursing() {
    return WorkerFormComponent.IS_NURSING;
  }

  ngOnInit() {
    this.isAdmin = this.authorizationService.hasRole(Role.SUPER_ADMIN);
    this.setWorker();
    this.formBuilderValidations();
    // In order to check async validators unique niss / email
    setTimeout(() => {
      this.workerForm.patchValue(this.workerForm.value);
    });

    this.translate.onLangChange.subscribe(() => {
      if (this.countries) {
        this.countries = convertCountries(this.countries, this.translate);
      }
    });
  }

  ngAfterViewInit() {
    this.translate.onLangChange.subscribe(() => {
      Object.assign(this.workerPositions, convertWorkerPosition(this.workerPositions, this.translate));
    });
  }

  hasRole() {
    return this.authorizationService.hasRole(Role.SUPERVISOR);
  }

  formBuilderValidations() {
    this.workerForm = this.formBuilder.group({
      id: [this.worker.id, []],
      workerNumber: [this.worker.workerNumber, []],
      firstName: [this.worker.firstName, [Validators.required]],
      lastName: [this.worker.lastName, [Validators.required]],
      operatingUnitId: [
        this.worker.operatingUnitId ? this.worker.operatingUnitId : this.authorizationService.getCurrentUser().operatingUnitId,
        [Validators.required]],
      address: this.formBuilder.group({
        street: [this.worker.address.street, [Validators.required]],
        number: [this.worker.address.number, [Validators.required]],
        box: [this.worker.address.box, []],
        zip: [this.worker.address.zip, [Validators.required]],
        city: [this.worker.address.city, [Validators.required]],
        country: [this.worker.address.country, [Validators.required]]
      }),
      niss: [this.worker.niss, [Validators.required]],
      gender: [this.worker.gender, [Validators.required]],
      mobile: [this.worker.mobile, [Validators.required]],
      email: [this.worker.email, [Validators.required, emailValidator]],
      dateOfBirth: [this.worker.dateOfBirth, [
        Validators.required,
        AgeCheckValidators.ageCheck(),
      ]
      ],
      placeOfBirth: [this.worker.placeOfBirth, []],
      languageCode: [this.worker.languageCode, [Validators.required]],
      workerPositions: [this.worker.workerPositions, []],
      studentQuotaHours: this.studentQuotaHoursArray,
      workerStatus: this.formBuilder.group({
        workerType: [this.worker.workerStatus.workerType, [Validators.required]],
        seniority: [this.worker.workerStatus.seniority ?? 0, [Validators.required]],
        studentJobQuotaStartDate: [this.worker.workerStatus.studentJobQuotaStartDate, []],
        studentJobQuotaHours: [this.worker.workerStatus.studentJobQuotaHours, []],
        studentAtWorkNumber: [this.worker.workerStatus.studentAtWorkNumber, []],
        horecaAtWorkQuotaStartDate: [this.worker.workerStatus.horecaAtWorkQuotaStartDate, []],
        horecaAtWorkQuotadays: [this.worker.workerStatus.horecaAtWorkQuotadays, [Validators.min(0)]],
        idCardNumber: [this.worker.workerStatus.idCardNumber, []],
        idCardEndOfValidity: [this.worker.workerStatus.idCardEndOfValidity, [
          Validators.required,
          MinimumDateValidators.dateMinimum('1900-01-01')]],
        nationality: [this.worker.workerStatus.nationality, [Validators.required]],
        residentPermitType: [this.worker.workerStatus.residentPermitType, [Validators.required]],
        residentPermitNumber: [this.worker.workerStatus.residentPermitNumber, []],
        residentPermitEndOfValidity: [this.worker.workerStatus.residentPermitEndOfValidity, [
          MinimumDateValidators.dateMinimum('1900-01-01')]
        ],
      }),
      bankData: this.formBuilder.group({
        bic: [this.worker.bankData.bic, [Validators.required]],
        iban: [this.worker.bankData.iban, [Validators.required, ibanValidator()]],
        diplomaNumber: [this.worker.bankData.diplomaNumber, []],
        visaNumber: [this.worker.bankData.visaNumber, []],
        inamiNumber: [this.worker.bankData.inamiNumber, []],
        positions: [this.worker.bankData.positions, []],
        licenseNumber: [this.worker.bankData.licenseNumber, []],
        expertize: [this.worker.bankData.expertize, []],
        deposit: [this.worker.bankData.deposit ?? 18, [Validators.required, Validators.min(0), Validators.max(100)]],
        withHoldingRate: [this.worker.bankData.withHoldingRate ?? 18, [Validators.required, Validators.min(0), Validators.max(100)]],
        deductionsSeizures: [this.worker.bankData.deductionsSeizures ? 'true' : 'false', []],
        invalid: [this.worker.bankData.invalid ? 'true' : 'false', []],
        familySituation: [this.worker.bankData.familySituation, [Validators.required]],
        dependentsNumber: [this.worker.bankData.dependentsNumber, [Validators.min(0)]],
        federalTargetGroupReduction: [this.worker.bankData.federalTargetGroupReduction, [Validators.min(0)]],
        walloniaTargetGroupReduction: [this.worker.bankData.walloniaTargetGroupReduction, [Validators.min(0)]],
        socialExclusionReport: [this.worker.bankData.socialExclusionReport ? 'true' : 'false', []]
      }),
      contractData: this.formBuilder.group({
        studyLevel: [this.worker.contractData.studyLevel, [Validators.required]],
        firstContractDate: [this.worker.contractData.firstContractDate, []],
        lastContractDate: [this.worker.contractData.lastContractDate, []],
        interimOnlinePlanning: [this.worker.contractData.interimOnlinePlanning, []],
        communicationTool: [this.worker.contractData.communicationTool, []],
        customerRemarks: [this.worker.contractData.customerRemarks, []],
        possibleEvaluation: [this.worker.contractData.possibleEvaluation, []],
        operatingUnitNotes: [this.worker.contractData.operatingUnitNotes, []]
      }),
      active: [this.worker.active, [Validators.required]],
    });

    if (WorkerFormComponent.IS_NURSING) {
      this.workerForm.addControl('medicalData', this.formBuilder.group({
        hepBDosage: [this.worker.medicalData.hepBDosage, []],
        medicalVisit: [this.worker.medicalData.medicalVisit ?? false, [Validators.required]],
        intradermo: [this.worker.medicalData.intradermo, []],
        socialRiskDeclaration1: [this.worker.medicalData.socialRiskDeclaration1, []],
        socialRiskDeclaration2: [this.worker.medicalData.socialRiskDeclaration2, []],
        socialRiskDeclaration3: [this.worker.medicalData.socialRiskDeclaration3, []],
        socialRiskDeclaration4: [this.worker.medicalData.socialRiskDeclaration4, []],
        socialRiskDeclaration5: [this.worker.medicalData.socialRiskDeclaration5, []],
        socialRiskDeclaration6: [this.worker.medicalData.socialRiskDeclaration6, []]
      }));
    }

    this.worker.studentQuotaHours.forEach(sqh => this.addRow(sqh));

    this.workerForm.controls.workerStatus.get('studentJobQuotaHours').valueChanges.subscribe(value => {
      const studentQuotaHours = this.workerForm.get('studentQuotaHours') as UntypedFormArray;
      studentQuotaHours.controls.forEach((control: AbstractControl) => {
        control.get('hoursDone').setValidators(Validators.max(value));
        control.get('hoursDone').markAsTouched();
        control.get('hoursDone').updateValueAndValidity();
      });
    });

    this.workerForm.controls.bankData.get('iban').valueChanges.subscribe(() => {
      const iban = this.workerForm.controls.bankData.get('iban');
      if (iban.valid && iban.value.replace(/\s/g, '').substring(0, 2) === 'BE') {
        this.facadeService.getBicByIdentificationNumber(iban.value.replace(/\s/g, '').substring(4, 7)).subscribe(
          (bic: Bic) => {
            this.workerForm.controls.bankData.get('bic').setValue(bic.bicCode);
          }
        );
      }
    });

    this.workerForm.controls.workerStatus.get('nationality').valueChanges.subscribe(() => {
      this.checkNissValidity();
    });

    this.workerForm.get('email').valueChanges.subscribe(() => {
      this.checkUniqueEmail();
    });

    this.workerForm.get('niss').valueChanges.subscribe(() => {
      this.checkNissValidity();
    });

    this.workerForm.get('dateOfBirth').valueChanges.subscribe(() => {
      if (this.workerForm.get('dateOfBirth').valid) {
        this.checkNissValidity();
      }
    });

    this.setNationalityValidators();
    this.setWorkerStatusValidators();
  }

  isEuropean() {
    const nationality = this.workerForm.controls.workerStatus.get('nationality').value;
    const country = this.countries.find(c => c.code === nationality);
    if (!country) {
      return null;
    }
    return country.isEuropean;
  }

  setNationalityValidators() {
    const nationality = this.workerForm.controls.workerStatus.get('nationality');
    const country = this.countries.find(c => c.code === nationality.value);
    const residentPermitType = this.workerForm.controls.workerStatus.get('residentPermitType');
    const residentPermitEndOfValidity = this.workerForm.controls.workerStatus.get('residentPermitEndOfValidity');
    const residentPermitNumber = this.workerForm.controls.workerStatus.get('residentPermitNumber');

    if (!country) {
      return;
    }

    if (!country.isEuropean) {
      residentPermitType.setValidators(Validators.required);
      residentPermitType.enable();
      residentPermitNumber.setValidators(Validators.required);
      residentPermitNumber.enable();
      residentPermitEndOfValidity.setValidators(Validators.required);
      residentPermitEndOfValidity.enable();
    } else {
      residentPermitType.clearValidators();
      residentPermitType.reset();
      residentPermitType.disable();
      residentPermitNumber.clearValidators();
      residentPermitNumber.reset();
      residentPermitNumber.disable();
      residentPermitEndOfValidity.clearValidators();
      residentPermitEndOfValidity.reset();
      residentPermitEndOfValidity.disable();
    }

    residentPermitType.updateValueAndValidity();
    residentPermitNumber.updateValueAndValidity();
    residentPermitEndOfValidity.updateValueAndValidity();
  }

  setResidentPermitTypeValidators() {
    const residentPermitType = this.workerForm.controls.workerStatus.get('residentPermitType');
    const residentPermitEndOfValidity = this.workerForm.controls.workerStatus.get('residentPermitEndOfValidity');
    const residentPermitNumber = this.workerForm.controls.workerStatus.get('residentPermitNumber');

    if (residentPermitType.value !== ResidentPermitType.E) {
      residentPermitType.setValidators(Validators.required);
      residentPermitType.enable();
      residentPermitNumber.setValidators(Validators.required);
      residentPermitNumber.enable();
      residentPermitEndOfValidity.setValidators(Validators.required);
      residentPermitEndOfValidity.enable();
    } else {
      residentPermitNumber.clearValidators();
      residentPermitNumber.reset();
      residentPermitNumber.disable();
      residentPermitEndOfValidity.clearValidators();
      residentPermitEndOfValidity.reset();
      residentPermitEndOfValidity.disable();
    }

    residentPermitType.updateValueAndValidity();
    residentPermitNumber.updateValueAndValidity();
    residentPermitEndOfValidity.updateValueAndValidity();
  }

  getEmailError() {
    if (!this.workerForm.get('email').errors) {
      return;
    }
    if (this.workerForm.get('email').errors.emailInvalid) {
      return this.translate.instant('workers.form.interimCard.message.error.notUniqueEmail');
    }
    return this.translate.instant('workers.form.interimCard.message.error.email');
  }

  getNissErrorMessage() {
    if (!this.workerForm.get('niss').errors) {
      return;
    }
    if (this.workerForm.get('niss').errors.required) {
      return this.translate.instant('workers.form.interimCard.message.error.niss.required');
    }
    return this.workerForm.get('niss').errors.message;
  }

  async onSubmit() {
    this.clicked = true;
    this.checkPermitValidity();
    await this.checkNissValidity();

    if (this.force && this.isAdmin) {
      this.sendWorker();
    }

    if (this.workerForm.invalid) {
      this.clicked = false;
      this.getFormValidationErrors(this.workerForm);
      const firstElementWithError = document.querySelector('.ng-invalid');
      if (firstElementWithError) {
        firstElementWithError.scrollIntoView({behavior: 'smooth'});
      }
      return this.openSnackBar(this.translate.instant('workers.form.message.error.form'), 'customSnackError');
    }

    this.sendWorker();
  }

  routeAfterSuccess() {
    if (this.worker.id) {
      this.openSnackBar(this.translate.instant('workers.form.message.success.save', {id: this.worker.id}), 'customSnackValid');
    } else {
      this.openSnackBar(this.translate.instant('workers.form.message.success.new', {id: this.worker.id}), 'customSnackValid');
    }
    return this.router.navigate(['workers']);
  }

  getResidentPermitTypeErrorMessage(): string {

    const nationality = this.workerForm.controls.workerStatus.get('nationality');
    const country = this.countries.find(c => c.code === nationality.value);

    if (!country) {
      return;
    }
    if (country.isEuropean) {
      return;
    }

    const residentPermitType = this.workerForm.controls.workerStatus.get('residentPermitType');

    if (residentPermitType.value === ResidentPermitType.E) {
      return;
    }

    const residentPermitEndOfValidity = this.workerForm.controls.workerStatus.get('residentPermitEndOfValidity');
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    today.setFullYear(today.getFullYear());
    const oneYearFromToday = new Date();
    oneYearFromToday.setFullYear(today.getFullYear() + 1)
    const residentPermitEndOfValidityDate = new Date(residentPermitEndOfValidity.value);
    residentPermitEndOfValidityDate.setHours(0, 0, 0, 0);

    if (residentPermitEndOfValidityDate.getTime() < today.getTime() && [
      ResidentPermitType.A,
      ResidentPermitType.B,
      ResidentPermitType.C,
      ResidentPermitType.D,
      ResidentPermitType.E,
      ResidentPermitType.F
    ].includes(residentPermitType.value)) {
      const date = new Date();
      date.setFullYear(today.getFullYear(), today.getMonth(), today.getDate());
      return this.translate.instant('workers.form.interimCard.message.error.permitTypeValidityDateMin') +
        (moment(date)).format('DD/MM/YYYY');
    }

    // PERMIS TYPE A = MAX CURRENT DATE + 1 YEAR
    if (residentPermitEndOfValidityDate.getTime() > oneYearFromToday.getTime() && [
      ResidentPermitType.A
    ].includes(residentPermitType.value)) {
      return this.translate.instant('workers.form.interimCard.message.error.permitTypeValidityDateMax') +
        (moment(oneYearFromToday)).format('DD/MM/YYYY');
    }

    return;
  }

  onBack(): void {
    this.location.back();
  }

  setWorkerStatusValidators() {
    const workerStatusType = this.workerForm.controls.workerStatus.get('workerType');
    const studentJobQuotaStartDate = this.workerForm.controls.workerStatus.get('studentJobQuotaStartDate');
    const studentJobQuotaHours = this.workerForm.controls.workerStatus.get('studentJobQuotaHours');
    const studentAtWorkNumber = this.workerForm.controls.workerStatus.get('studentAtWorkNumber');
    const horecaAtWorkQuotaStartDate = this.workerForm.controls.workerStatus.get('horecaAtWorkQuotaStartDate');
    const horecaAtWorkQuotadays = this.workerForm.controls.workerStatus.get('horecaAtWorkQuotadays');

    if (workerStatusType.value === WorkerType.STUDENT_NOT_SUBMITTED) {
      studentJobQuotaStartDate.enable();
      studentJobQuotaHours.enable();
      studentJobQuotaHours.setValidators([Validators.min(0), Validators.required]);
      studentAtWorkNumber.enable();
      horecaAtWorkQuotaStartDate.enable();
      horecaAtWorkQuotadays.enable();
      horecaAtWorkQuotadays.setValidators(Validators.min(0));
    } else {
      studentJobQuotaStartDate.setValue(null);
      studentJobQuotaStartDate.disable();
      studentJobQuotaHours.setValidators(null);
      studentJobQuotaHours.setValue(null);
      studentJobQuotaHours.disable();
      studentAtWorkNumber.setValue(null);
      studentAtWorkNumber.disable();
      horecaAtWorkQuotaStartDate.setValue(null);
      horecaAtWorkQuotaStartDate.disable();
      horecaAtWorkQuotadays.setValue(null);
      horecaAtWorkQuotadays.disable();
      horecaAtWorkQuotadays.setValidators(null);
    }

    studentJobQuotaStartDate.updateValueAndValidity();
    studentJobQuotaHours.updateValueAndValidity();
    studentAtWorkNumber.updateValueAndValidity();
    horecaAtWorkQuotaStartDate.updateValueAndValidity();
    horecaAtWorkQuotadays.updateValueAndValidity();
  }

  isNotStudent(): boolean {
    const type = this.workerForm.controls.workerStatus.get('workerType').value;
    return !(type === WorkerType.STUDENT_SUBMITTED || type === WorkerType.STUDENT_NOT_SUBMITTED);
  }

  getActualIndex(index: number) {
    return index + this.paginatorQuotaHours.pageSize * this.paginatorQuotaHours.pageIndex;
  }

  getMinYear() {
    return WorkerFormComponent.MIN_YEAR_STUDENT;
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }

  getErrorYear(sqh): string {
    const year = sqh.year;
    if (year < WorkerFormComponent.MIN_YEAR_STUDENT) {
      return this.translate.instant('workers.form.status.student.message.error.minYearStudentHours', {
        year: WorkerFormComponent.MIN_YEAR_STUDENT
      });
    }
    const studentQuotaHours = this.workerForm.get('studentQuotaHours') as UntypedFormArray;
    const hoursDone = studentQuotaHours.controls.find(s => s.get('year').value === year).get('hoursDone').value;
    if (!hoursDone) {
      return;
    }
    const max = this.workerForm.controls.workerStatus.get('studentJobQuotaHours').value;
    if (hoursDone < 0 || hoursDone > max) {
      return this.translate.instant('workers.form.status.student.message.error.hoursDoneStudent', {hours: max});
    }
    return '';
  }

  addNewRowWithYear(year: string) {
    if (!year || +year < WorkerFormComponent.MIN_YEAR_STUDENT) {
      return;
    }
    const values: StudentQuotaHour[] = this.studentQuotaHoursArray.value;
    if (values.find(sqh => sqh.year === +year)) {
      return;
    }
    const newQuotaHours = new StudentQuotaHour();
    newQuotaHours.year = +year;
    newQuotaHours.hoursDone = 0;
    this.addRow(newQuotaHours);
  }

  deleteRow(sqh: StudentQuotaHour) {
    const studentQuotaHours = this.workerForm.get('studentQuotaHours') as UntypedFormArray;
    this.studentQuotaHoursArray.removeAt(studentQuotaHours.value.findIndex(s => s.year === sqh.year));
    this.quotaHours.data = studentQuotaHours.value;
  }


  // ------------  Student quota hours functions ------------

  openSnackBar(message: string, pC: string, action: string = 'X') {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  private checkUniqueEmail() {

    const email = this.workerForm.get('email');

    if (!email.value || email.value.replace(/\s/g, '') === '') {
      return;
    }

    if (email.valid) {

      if (!this.workerLiteId && email.value === this.worker.email) {
        return;
      }

      this.facadeService.existByEmail(email.value.replace(/\s/g, '')).subscribe(
        (bool: boolean) => {
          if (bool) {
            this.workerForm.get('email').markAsTouched({onlySelf: true});
            this.workerForm.get('email').setErrors({'emailInvalid': true});
          } else {
            this.workerForm.get('email').setErrors(null);
          }
        }
      );
    }
  }

  private checkNissValidity() {

    const nissControl = this.workerForm.get('niss');

    if (!nissControl.value
      || nissControl.value.replace(/\s/g, '') === '') {
      return;
    }

    if (nissControl.value && this.workerForm.controls.workerStatus.get('nationality').value !== 'BE') {
      nissControl.setErrors(null);
      return;
    }

    const niss = nissControl.value.toString();

    const birthDate: Date = this.workerForm.get('dateOfBirth').value;
    // https://www.ksz-bcss.fgov.be/fr/services-et-support/services/registre-national-registres-bcss

    this.workerForm.get('niss').markAsTouched({onlySelf: true});

    if (niss.length !== 11) {
      this.workerForm.get('niss').setErrors({
        'nissLength': true,
        message: this.translate.instant('workers.form.interimCard.message.error.niss.nissLength')
      });
      return;
    }
    if (niss.substring(0, 2) !== birthDate.getFullYear().toString().substring(2, 4)) {
      this.workerForm.get('niss').setErrors({
        'nissYear': true,
        message: this.translate.instant('workers.form.interimCard.message.error.niss.nissYear')
      });
      return;
    }
    if (+niss.substring(2, 4) - 1 !== birthDate.getMonth()) {
      this.workerForm.get('niss').setErrors({
        'nissMonth': true,
        message: this.translate.instant('workers.form.interimCard.message.error.niss.nissMonth')
      });
      return;
    }
    if (+niss.substring(4, 6) !== birthDate.getDate()) {
      this.workerForm.get('niss').setErrors({
        'nissDay': true,
        message: this.translate.instant('workers.form.interimCard.message.error.niss.nissDay')
      });
      return;
    }

    let nc: number;
    if (birthDate.getFullYear() < 2000) {
      nc = 97 - (+niss.substring(0, 9) % 97);
    } else {
      nc = 97 - (+('2' + niss.substring(0, 9)) % 97);
    }

    if (+niss.substring(9, 12) !== nc) {
      this.workerForm.get('niss').setErrors({
        'nissControl': true,
        message: this.translate.instant('workers.form.interimCard.message.error.niss.nissControl', {
          nc: nc.toString().padStart(2, '0')
        })
      });
      return;
    }

    if (niss === this.worker.niss) {
      this.workerForm.get('niss').setErrors(null);
      return;
    }

    this.facadeService.existByNiss(this.workerForm.get('niss').value.replace(/\s/g, '')).subscribe(
      (bool: boolean) => {
        if (bool) {
          this.workerForm.get('niss').markAsTouched({onlySelf: true});
          this.workerForm.get('niss').setErrors({
            'nissDuplicate': true,
            message: this.translate.instant('workers.form.interimCard.message.error.niss.notUniqueNiss')
          });
          return;
        }
      }
    );
  }

  private sendWorker() {
    Object.assign(this.worker, this.workerForm.value);

    this.facadeService.saveWorker(this.worker).subscribe({
      next: () => {
        if (this.workerLiteId) {
          this.facadeService.deactivateWorkerLite(this.workerLiteId).subscribe({
              next: () => {
                this.openSnackBar(this.translate.instant('workers.form.message.success.save'), 'customSnackValid');
                this.router.navigate(['workers']).then();
              },
              error: () => {
                this.clicked = false;
              }
            }
          );
        } else {
          if (this.route.snapshot.queryParamMap.get('action') === DimonaErrorAction.INVALID_NISS) {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent,
              {data: {message: this.translate.instant('workers.form.message.submit')}});
            dialogRef.afterClosed().subscribe(result => {
              if (result === true) {
                this.facadeService.submitDimona(this.route.snapshot.queryParamMap.get('d')).subscribe({
                  next: () => {
                    this.routeAfterSuccess().then();
                  },
                  error: () => {
                    this.clicked = false;
                  }
                });
              } else {
                this.routeAfterSuccess().then();
              }
            });
          } else {
            this.routeAfterSuccess().then();
          }
        }
      },
      error: () => {
        this.clicked = false;
      }
    });
  }

  private setWorker() {
    if (!this.worker) {
      this.worker = new Worker();
    }
    this.setPaginator(this.worker.studentQuotaHours);
  }

  private setPaginator(sqh: StudentQuotaHour[]) {
    this.quotaHours = new MatTableDataSource<StudentQuotaHour>(sqh);
    this.quotaHours.paginator = this.paginatorQuotaHours;
  }

  private checkPermitValidity() {
    const nationality = this.workerForm.controls.workerStatus.get('nationality');
    const country = this.countries.find(c => c.code === nationality.value);

    if (!country) {
      return;
    }

    if (!country.isEuropean) {
      const residentPermitType = this.workerForm.controls.workerStatus.get('residentPermitType');

      if (residentPermitType.value === ResidentPermitType.E) {
        return;
      }

      const residentPermitEndOfValidity = this.workerForm.controls.workerStatus.get('residentPermitEndOfValidity');
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      today.setFullYear(today.getFullYear());
      const oneYearFromToday = new Date();
      oneYearFromToday.setFullYear(today.getFullYear() + 1)
      const residentPermitEndOfValidityDate = new Date(residentPermitEndOfValidity.value);
      residentPermitEndOfValidityDate.setHours(0, 0, 0, 0);

      let validPermitType;
      if (residentPermitEndOfValidityDate.getTime() >= today.getTime()) {
        validPermitType = [
          ResidentPermitType.B,
          ResidentPermitType.C,
          ResidentPermitType.D,
          ResidentPermitType.E,
          ResidentPermitType.F
        ].includes(residentPermitType.value);

        // PERMIS TYPE A = MAX CURRENT DATE + 1 YEAR
        if (residentPermitEndOfValidityDate.getTime() <= oneYearFromToday.getTime()) {
          validPermitType = [
            ResidentPermitType.A,
            ResidentPermitType.B,
            ResidentPermitType.C,
            ResidentPermitType.D,
            ResidentPermitType.E,
            ResidentPermitType.F
          ].includes(residentPermitType.value);
        }
      }

      if (!validPermitType) {
        this.clicked = false;
        residentPermitType.setErrors({'permit-type-invalid': true});
        return this.openSnackBar(this.translate.instant('workers.form.message.error.permitType'), 'customSnackError');
      }
      residentPermitType.setErrors(null);
      residentPermitType.updateValueAndValidity();
    }
  }

  private getFormValidationErrors(form: UntypedFormGroup) {
    Object.keys(form.controls).forEach(key => {

      if (form.get(key) instanceof UntypedFormGroup) {
        this.getFormValidationErrors(form.get(key) as UntypedFormGroup);
      }
    });
  }

  private addRow(sqh?: StudentQuotaHour) {
    const studentQuotaHours = this.workerForm.get('studentQuotaHours') as UntypedFormArray;
    studentQuotaHours.push(this.createQuotaHoursFormGroup(sqh));
    const sorted = studentQuotaHours.value.sort((a, b) => a.year - b.year);
    studentQuotaHours.patchValue(sorted);
    this.quotaHours.data = studentQuotaHours.value;
  }

  private createQuotaHoursFormGroup(sqh: StudentQuotaHour): UntypedFormGroup {
    return new UntypedFormGroup({
      year: new UntypedFormControl(sqh.year, [Validators.required, Validators.min(WorkerFormComponent.MIN_YEAR_STUDENT)]),
      hoursDone: new UntypedFormControl(sqh.hoursDone, [
        Validators.required, Validators.min(0),
        Validators.max(this.workerForm.controls.workerStatus.get('studentJobQuotaHours').value)
      ])
    });
  }
}
