export enum PrestationCodeType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
  AUTOMATIC_TOTAL_BASED = 'AUTOMATIC_TOTAL_BASED'
}

export enum SalaryType {
  NUMBER,
  DAYS,
  TOTAL_BASED_SALARY
}

export enum SSEncodingType {
  SALARY = 'SALARY',
  DURATION_DAYS = 'DURATION_DAYS',
  DURATION_MINUTES = 'DURATION_MINUTES',
  UNIT_VALUE = 'UNIT_VALUE'
}

export enum Priority {
  HIGH = 'HIGH',
  NORMAL = 'NORMAL'
}

export enum SSCodeType {
  SALARY = 'SALARY',
  PRESTATION = 'PRESTATION'
}


export class PrestationCode {
  id: number;
  code: string;
  labelFR: string;
  labelNL: string;
  labelEN: string;
  labelDE: string;
  prestationCodeType: PrestationCodeType;
  processorClassName: string;
  processorArguments: string;
  ruleDescription: string;
  daily: boolean;
  hourly: boolean;
  totalBased: boolean;
  prestation: boolean;
  justification: boolean;
  prestationPercent: number;
  billing: boolean;
  billingPercent: number;
  validation: boolean;
  correction: boolean;
  ssCode: string;
  ssCodeType: string;
  ssEncodingType: string;
  account: string;
  accountingShortLabel: string;
  accountingLabelFR: string;
//   jointCommissions: string[];
  jointCommissions: string;
  active: boolean;
  priority: string;

  getSalaryType(): SalaryType {
    if (this.totalBased) {
      return SalaryType.TOTAL_BASED_SALARY;
    }

    if (this.hourly) {
      return SalaryType.NUMBER;
    }

    return SalaryType.DAYS;
  }
}
