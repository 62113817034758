import {Serializer} from 'src/app/shared/model/serializer';
import {JointCommission} from './joint-commission.model';
import {DatePipe} from '@angular/common';
import { Sector } from '../sector/sector.model'
import { SectorSerializer } from '../sector/sector.serializer'


export class JointCommissionSerializer implements Serializer {
  private datePipe: DatePipe = new DatePipe('en');
  private sectorSerializer: SectorSerializer = new SectorSerializer();

  fromJson(json: any): JointCommission {
    const joinCommission: JointCommission = new JointCommission();
    let sector: Sector = new Sector();

    joinCommission.id = json.id;
    joinCommission.active = json.active;
    joinCommission.code = json.code;
    joinCommission.nameDE = json.nameDE;
    joinCommission.nameEN = json.nameEN;
    joinCommission.nameFR = json.nameFR;
    joinCommission.nameNL = json.nameNL;
    joinCommission.starred = json.starred;
    joinCommission.validityEndDate = (json.validityEndDate) ? new Date(json.validityEndDate) : null;
    joinCommission.validityStartDate = (json.validityStartDate) ? new Date(json.validityStartDate) : null;

    const jsonSector = json.sector;
    if (typeof(jsonSector) !== 'number') {
      joinCommission.sector = this.sectorSerializer.fromJson(jsonSector);
    } else {
      joinCommission.sector = new Sector();
      joinCommission.sector.id = json.sector;
    }

    return joinCommission;
  }

  toJson(jointCommission: JointCommission): any {
    return {
      id: jointCommission.id,
      active: jointCommission.active,
      code: jointCommission.code,
      nameDE: jointCommission.nameDE,
      nameEN: jointCommission.nameEN,
      nameFR: jointCommission.nameFR,
      nameNL: jointCommission.nameNL,
      starred: jointCommission.starred,
      validityEndDate: (jointCommission.validityEndDate) ? this.datePipe.transform(jointCommission.validityEndDate, 'yyyy-MM-dd') : null,
      validityStartDate: (jointCommission.validityEndDate) ? this.datePipe.transform(jointCommission.validityEndDate, 'yyyy-MM-dd') : null,
      sector: this.sectorSerializer.toJson(jointCommission.sector)
    };
  }
}
