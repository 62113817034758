import { saveAs } from 'file-saver';
import { WorkFunction } from './../../model/work-function/work-function.model';
import {Injectable, Injector} from '@angular/core';
import {RequestService} from 'src/app/requests/shared/service/request.service';
import {CustomerService} from 'src/app/customers/shared/service/customer.service';
import {ContractService} from 'src/app/contracts/shared/service/contract.service';
import {OperatingUnitService} from 'src/app/operating-units/shared/service/operating-unit.service';
import {UserService} from 'src/app/users/shared/service/user.service';
import {WorkerService} from 'src/app/workers/shared/service/worker.service';
import {BillingCoefficients, Customer, CustomerSize} from 'src/app/customers/shared/model/customer.model';
import {OperatingUnit} from 'src/app/operating-units/shared/model/operating-unit.model';
import {Request} from 'src/app/requests/shared/model/request.model';
import {Worker, WorkerType} from 'src/app/workers/shared/model/worker.model';
import {Observable} from 'rxjs';
import {NonAvailabilityService} from 'src/app/workers/shared/service/non-availability.service';
import {NonAvailability} from 'src/app/workers/shared/model/nonAvailability.model';
import {ReferenceDataService} from '../reference-data/reference-data.service';
import {PrestationService} from '../../../prestations/shared/service/prestation.service';
import {Prestation} from '../../../prestations/shared/model/prestation.model';
import {PrestationPeriodService} from '../../../prestations/shared/service/prestation-period.service';
import {RegistrationFormService} from '../../../workers/shared/service/registration-form.service';
import {PrestationPeriod} from '../../../prestations/shared/model/prestation-period.model';
import {DimonaService} from '../dimona/dimona.service';
import {DocumentService} from '../document/document.service';
import {Document, TypeFileCustomer, TypeFileWorker} from '../../model/document/document.model';
import {WorkersLiteService} from '../../../workers-lite/shared/service/workers-lite.service';
import {InvoiceService} from '../../../bills/shared/service/invoice.service';
import {User} from '../../../users/shared/model/user.model';
import {PlanningService} from '../../../planning/shared/service/planning.service';
import {StatusService} from '../../../admin/service/status.service';
import {ElasticSearchService} from '../elastic-search/elastic-search.service';
import {Login} from '../../../login/shared/model/login.model';
import { PublicHoliday } from 'src/app/admin/model/public-holiday.model';
import { JointCommissionCoefficientAdmin } from 'src/app/admin/model/joint-commission-coefficient.model';
import { TravelExpenseCoefficient } from 'src/app/admin/model/travel-expense-coefficient.model';
import { StudentQuotaHours } from 'src/app/admin/model/student-quota-hours.model';
import { SalaryScale } from 'src/app/admin/model/salary-scale.model';
import { JointCommission } from 'src/app/shared/model/joint-commission/joint-commission.model';
import { PrestationCode } from 'src/app/shared/model/prestation-code/prestation-code.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Contract } from 'src/app/contracts/shared/model/contract.model';

@Injectable({
  providedIn: 'root'
})
export class FacadeService {
  private _registrationFormService: RegistrationFormService;

  constructor(private injector: Injector,
    private translate: TranslateService,
    private snackBar: MatSnackBar) {
  }

  /*************************/
  /*       Contract        */
  /*************************/
  private _contractService: ContractService;

  private get contractService(): ContractService {
    if (!this._contractService) {
      this._contractService = this.injector.get(ContractService);
    }
    return this._contractService;
  }

  getContractById(contractId: number): Observable<any> {
    return this.contractService.get(contractId);
  }

  getContractOriginalFileById(contractId: number): Observable<any> {
    return this.contractService.getOriginalfile(contractId);
  }

  generatePdfById(contractId: number): Observable<any> {
    return this.contractService.generatePdf(contractId);
  }

  getPageContract(index: number, size: number, options: { query?: string, requestId?: number, active?: boolean }): Observable<any> {
    if (!options.query) {
      return this.contractService.getPage(index, size, options.requestId, options.active);
    }
    return this.elasticSearchService.filterContracts(index, size, options.query);
  }

  getMinimumSalaryScale(workerType: WorkerType, workFunctionId: number, workerId: number, customerId: number): Observable<any> {
    return this.contractService.getMinimumSalaryScale(workerType, workFunctionId, workerId, customerId);
  }

  getStudentRate(age: number): Observable<any> {
    return this.contractService.getStudentRate(age);
  }

  deleteContract(contract: Contract): Observable<any> {
    return this.contractService.delete(contract);
  }

  getContractToExport(startDate: Date,
                        endDate: Date,
                        operationUnitIds: Array<number>,
                        workerId: number,
                        customerId: number): Observable<any> {
    return this.contractService.export(startDate, endDate, operationUnitIds, workerId, customerId);
  }

  getSuccessiveContractsToExport(start: Date, end: Date): Observable<any> {
    return this.contractService.exportSuccessiveContracts(start, end);
  }

  /*************************/
  /*        Customer       */
  /*************************/
  private _customerService: CustomerService;

  private get customerService(): CustomerService {
    if (!this._customerService) {
      this._customerService = this.injector.get(CustomerService);
    }
    return this._customerService;
  }

  activeCustomer(customer: Customer): Observable<any> {
    return this.customerService.activate(customer);
  }

  deactivateCustomer(customer: Customer): Observable<any> {
    return this.customerService.deactivate(customer);
  }

  saveCustomer(customer: Customer): Observable<any> {
    return this.customerService.save(customer);
  }

  getAllCustomers(): Observable<any> {
    return this.customerService.list();
  }

  getPageCustomer(index: number, page: number, options: { query?: string }): Observable<any> {
    if (!options.query) {
      return this.customerService.getPageCustomer(index, page);
    }

    return this.elasticSearchService.filterCustomers(options.query, index, page);
  }

  getAllActiveCustomers(): Observable<any> {
    return this.customerService.listActive();
  }

  getCustomerById(customerId: number): Observable<any> {
    return this.customerService.get(customerId);
  }

  getCustomerToExport(startDate: Date, endDate: Date, operationUnitIds: Array<number>): Observable<any> {
    return this.customerService.export(startDate, endDate, operationUnitIds);
  }

  getInfoDatabe(vatNumber: string): Observable<any> {
    return this.customerService.getInfo(vatNumber);
  }

  /*************************/
  /*     OperatingUnit     */
  /*************************/
  private _operatingUnitService: OperatingUnitService;

  private get operatingUnitService(): OperatingUnitService {
    if (!this._operatingUnitService) {
      this._operatingUnitService = this.injector.get(OperatingUnitService);
    }
    return this._operatingUnitService;
  }

  saveOperatingUnit(operatingUnit: OperatingUnit): Observable<any> {
      return this.operatingUnitService.save(operatingUnit);
    }

    getOperatingUnitById(operatingUnitId: number): Observable<any> {
      return this.operatingUnitService.get(operatingUnitId);
    }

    getAllOperatingUnits(): Observable<any> {
      return this.operatingUnitService.list();
    }

    getAllActiveOperatingUnits(): Observable<any> {
      return this.operatingUnitService.listActive();
    }

    getPageOperatingUnits(index: number, page: number, options: { query?: string }): Observable<any> {
      if (!options.query) {
        return this.operatingUnitService.getPageOperatingUnits(index, page);
      }
      return this.elasticSearchService.filterOperatingUnits(options.query, index, page);
    }

    deleteOperatingUnit(operatingUnit: OperatingUnit): Observable<any> {
      return this.operatingUnitService.delete(operatingUnit);
    }

    activeOperatingUnit(operatingUnit: OperatingUnit): Observable<any> {
      return this.operatingUnitService.active(operatingUnit);
    }

  /*************************/
  /*   PrestationPeriod    */
  /*************************/
  private _periodPrestationService: PrestationPeriodService;

  private get periodPrestationService(): PrestationPeriodService {
    if (!this._periodPrestationService) {
      this._periodPrestationService = this.injector.get(PrestationPeriodService);
    }
    return this._periodPrestationService;
  }

  /*************************/
  /*       Prestation      */
  /*************************/
  private _prestationService: PrestationService;

  private get prestationService(): PrestationService {
    if (!this._prestationService) {
      this._prestationService = this.injector.get(PrestationService);
    }
    return this._prestationService;
  }

  generatePtg(): Observable<any> {
    return this.prestationService.releasePayroll();
  }

  generatePtgByContract(): Observable<any> {
    return this.prestationService.releasePayrollByContract();
  }

  // Old prestation export //
  getPrestationToExport(startDate: Date,
                        endDate: Date,
                        operationUnitIds: Array<number>,
                        customerId: number,
                        workerId: number): Observable<any> {
    return this.prestationService.exportPrestationCsv(startDate, endDate, operationUnitIds, customerId, workerId);
  }

  // New prestation export (2023) //
  getPrestationNewToExport(operatingUnitIds: Array<number>,
    customerId: number,
    workerId: number,
    fromDate: Date,
    toDate: Date): Observable<any> {
    return this.prestationService.exportPrestationNewCsv(operatingUnitIds, customerId, workerId, fromDate, toDate);
  }

  // Code prestation export (2023) //
  getCodePrestationToExport(fromDate: Date,
    toDate: Date,
    operatingUnitIds: Array<number>,
    customerId: number,
    workerId: number): Observable<any> {
    return this.prestationService.exportCodePrestationCsv(fromDate, toDate, operatingUnitIds, customerId, workerId);
  }

  releasePayroll(): Observable<any> {
    return this.prestationService.releasePayroll();
  }

  modifyPrestationPeriod(period: PrestationPeriod): Observable<any> {
    return this.periodPrestationService.modify(period);
  }

  rejectPrestationPeriod(period: PrestationPeriod, justificationCode: String): Observable<any> {
    return this.periodPrestationService.reject(period, justificationCode);
  }

  cancelPrestationPeriod(period: PrestationPeriod, justificationCode: String): Observable<any> {
    return this.periodPrestationService.cancel(period, justificationCode);
  }

  validatePrestationPeriod(period: PrestationPeriod): Observable<any> {
    return this.periodPrestationService.validate(period);
  }

  prestationSendReminder(): Observable<any> {
    return this.prestationService.sendReminder();
  }

  getActivityReportToExport(
    startDate,
    endDate,
    operatingUnitIds) {
    return this.prestationService.exportActivityReport(startDate, endDate, operatingUnitIds);
  }

  getInfoDutyReportToExport(
    startDate,
    endDate) {
    return this.prestationService.exportInfoDutyReport(startDate, endDate);
  }

  getPrestationByRequestId(requestId: number) {
    return this.prestationService.filterByRequestId(requestId);
  }

  getPrestationCorrectionFromRequest(requestId: number) {
    return this.prestationService.getPrestationCorrectionFromRequest(requestId);
  }

  filterPrestationForCorrection(id: number,
                                customerId: number,
                                startDate: Date,
                                endDate: Date,
                                workerId: number,
                                billingDate: Date,
                                dueDate: Date,
                                processingDate: Date,
                                billNumber: string) {
    return this.prestationService.filterForCorrection(
      id,
      customerId,
      startDate,
      endDate,
      workerId,
      billingDate,
      dueDate,
      processingDate,
      billNumber);
  }

  filterPrestation(startDate: Date,
                   endDate: Date,
                   workerId: number) {
    return this.prestationService.filter(
      startDate,
      endDate,
      workerId);
  }

  filterPrestationWithValidContracts(requestId: number) {
    return this.prestationService.filterWithValidContracts(requestId);
  }

  getPagePrestationToValidate(index: number, size: number, options: { requestId?: number }): Observable<any> {
    return this.prestationService.listPageToValidate(index, size, options.requestId);
  }

  getPagePrestationReleased(index: number, size: number): Observable<any> {
    return this.prestationService.listPageReleased(index, size);
  }

  getPagePrestationToRelease(index: number, size: number): Observable<any> {
    return this.prestationService.listPageToRelease(index, size);
  }

  findIndexToRelease(id: number, size: number): Observable<any> {
    return this.prestationService.findIndexToRelease(id, size);
  }

  validatePrestation(prestation: Prestation): Observable<any> {
    return this.prestationService.validate(prestation);
  }

  /*************************/
  /*         Request       */
  /*************************/
  private _requestService: RequestService;

  private get requestService(): RequestService {
    if (!this._requestService) {
      this._requestService = this.injector.get(RequestService);
    }
    return this._requestService;
  }

  copyRequestWithNewDate(request: Request): Observable<any> {
    return this.requestService.copyWithNewDate(request);
  }

  unpaidRequest(request: Request): Observable<any> {
    return this.requestService.unpaid(request);
  }

  paidRequest(request: Request): Observable<any> {
    return this.requestService.paid(request);
  }

  getPageRequestsWithError(index: number, size: number): Observable<any> {
    return this.requestService.getPageErrors(index, size);
  }

  getPendingRequestByWorker(id: number): Observable<any> {
    return this.requestService.getPendingRequestByWorker(id);
  }

  getPendingRequestByCustomer(id: number): Observable<any> {
    return this.requestService.getPendingRequestByCustomer(id);
  }

  getPageDeadlineRequests(index: number, size: number): Observable<any> {
    return this.requestService.getPageDeadline(index, size);
  }

  modifyRequest(request: Request): Observable<any> {
    return this.requestService.modify(request);
  }

  validateRequest(request: Request): Observable<any> {
    return this.requestService.validate(request);
  }

  saveRequest(request: Request): Observable<any> {
    return this.requestService.save(request);
  }

  getRequestById(requestId: number): Observable<any> {
    return this.requestService.get(requestId);
  }

  getRequestPage(index: number, size: number, options: { query?: string }): Observable<any> {
    if (!options.query) {
      return this.requestService.getPage(index, size);
    }
    return this.elasticSearchService.filterRequests(options.query, index, size);
  }

  getPagePendingRequests(index: number, size: number, options: { customerId?: number }): Observable<any> {
    return this.requestService.getPagePending(index, size, options.customerId);
  }

  deleteRequest(request: Request): Observable<any> {
    return this.requestService.delete(request);
  }

  /*************************/
  /*         User          */
  /*************************/
  private _userService: UserService;

  private get userService(): UserService {
    if (!this._userService) {
      this._userService = this.injector.get(UserService);
    }
    return this._userService;
  }

  checkCustomerEmailTaken(id: number, email: string): Observable<any> {
      return this.userService.checkCustomerEmailTaken(id, email);
    }

  createCustomerAccount(id: number, email: string): Observable<any> {
    return this.userService.createCustomerAccount(id, email);
  }

  getPageUsersConsultants(index: number, size: number): Observable<any> {
    return this.userService.getPageUsersConsultants(index, size);
  }

  getPageUsersWorkers(index: number, size: number): Observable<any> {
    return this.userService.getPageUsersWorkers(index, size);
  }

  getPageUsersCustomers(index: number, size: number): Observable<any> {
    return this.userService.getPageUsersCustomers(index, size);
  }

  authorize(login: Login): Observable<any> {
    return this.userService.authorize(login);
  }

  refreshToken(refreshToken: string): Observable<any> {
    return this.userService.refreshToken(refreshToken);
  }

  getByToken(token: string): Observable<any> {
    return this.userService.getByToken(token);
  }

  getUserById(userId): Observable<any> {
    return this.userService.get(userId);
  }

  getUserByUuid(uuid: string): Observable<any> {
    return this.userService.getByUuid(uuid);
  }

  create(user: User): Observable<any> {
    return this.userService.create(user);
  }

  saveUser(user: User): Observable<any> {
    return this.userService.save(user);
  }

  updateUserPassword(password: string, newPassword: string, username: string, uuid?: string): Observable<any> {
    return this.userService.updatePassword(password, newPassword, username, uuid);
  }

  changePasswordAdmin(username: string, newPassword: string): Observable<any> {
    return this.userService.changePasswordAdmin(username, newPassword);
  }

  resetPassword(username: string, lang: string): Observable<any> {
    return this.userService.resetPassword(username, lang);
  }

  createWorkerAccount(id: number): Observable<any> {
    return this.userService.createWorkerAccount(id);
  }

  checkWorkerEmailTaken(email: string): Observable<any> {
    return this.userService.checkWorkerEmailTaken(email);
  }

  /*************************/
  /*        Worker         */
  /*************************/
  private _workerService: WorkerService;

  private get workerService(): WorkerService {
    if (!this._workerService) {
      this._workerService = this.injector.get(WorkerService);
    }
    return this._workerService;
  }

  generateIncompleteWorkers(): Observable<any> {
    return this.workerService.generateIncompleteWorkers();
  }


  getWorkerToExport(startDate: Date, endDate: Date, operationUnitIds: Array<number>): Observable<any> {
    return this.workerService.export(startDate, endDate, operationUnitIds);
  }

  activeWorker(worker: Worker): Observable<any> {
    return this.workerService.activate(worker);
  }

  deactivateWorker(worker: Worker): Observable<any> {
    return this.workerService.deactivate(worker);
  }

  saveWorker(worker: Worker): Observable<any> {
    return this.workerService.save(worker);
  }

  getWorkerById(workerId: number): Observable<any> {
    return this.workerService.get(workerId);
  }

  existByEmail(email: string): Observable<any> {
    return this.workerService.existByEmail(email);
  }

  existByNiss(niss: string): Observable<any> {
    return this.workerService.existByNiss(niss);
  }

  getAllWorkers(): Observable<any> {
    return this.workerService.list();
  }

  getAllActiveWorkers(): Observable<any> {
    return this.workerService.listActive();
  }

  getPageWorkers(index: number, size: number, options: { query?: string }): Observable<any> {
    if (!options.query) {
      return this.workerService.getPage(index, size);
    }
    return this.elasticSearchService.filterWorker(options.query, index, size);
  }

  /*************************/
  /*      WorkersLite      */
  /*************************/
  private _workersLiteService: WorkersLiteService;

  private get workersLiteService(): WorkersLiteService {
    if (!this._workersLiteService) {
      this._workersLiteService = this.injector.get(WorkersLiteService);
    }
    return this._workersLiteService;
  }

  getWorkerLiteById(workerLiteId: number): Observable<any> {
    return this.workersLiteService.get(workerLiteId);
  }

  deactivateWorkerLite(workerLiteId: number): Observable<any> {
    return this.workersLiteService.delete(workerLiteId);
  }

  getPageWorkerLite(index: number, page: number): Observable<any> {
    return this.workersLiteService.getPageWorkerLite(index, page);
  }

  /*************************/
  /*    NonAvailability    */
  /*************************/
  private _nonAvailabilityService: NonAvailabilityService;

  private get nonAvailabilityService(): NonAvailabilityService {
    if (!this._nonAvailabilityService) {
      this._nonAvailabilityService = this.injector.get(NonAvailabilityService);
    }
    return this._nonAvailabilityService;
  }

  deleteNonAvailability(nonAvailability: NonAvailability): Observable<any> {
    return this.nonAvailabilityService.delete(nonAvailability);
  }

  saveNonAvailability(nonAvailability: NonAvailability): Observable<any> {
    return this.nonAvailabilityService.save(nonAvailability);
  }

  getNonAvailabilitiesFilter(workerId: number, startDate: Date = null, endDate: Date = null): Observable<any> {
    return this.nonAvailabilityService.filter(workerId, startDate, endDate);
  }

  /*************************/
  /*    RegistrationForm   */
  /*************************/
  private get registrationService(): RegistrationFormService {
    if (!this._registrationFormService) {
      this._registrationFormService = this.injector.get(RegistrationFormService);
    }
    return this._registrationFormService;
  }

  /*************************/
  /*     ReferenceData     */
  /*************************/
  private _referenceDataService: ReferenceDataService;

  private get referenceDataService(): ReferenceDataService {
    if (!this._referenceDataService) {
      this._referenceDataService = this.injector.get(ReferenceDataService);
    }
    return this._referenceDataService;
  }

  // StudentQuotaHours
  getMaxStudentQuotaHoursByYear(year: number): Observable<any> {
    return this.referenceDataService.getMaxStudentQuotaHoursByYear(year);
  }

  getPageStudentQuotaHours(index: number, size: number): Observable<any> {
    return this.referenceDataService.getPageStudentQuotaHours(index, size);
  }

  saveStudentQuotaHours(studentQuotaHours: StudentQuotaHours): Observable<any> {
    return this.referenceDataService.saveStudentQuotaHours(studentQuotaHours);
  }

  // companyPrefix
  listCompanyPrefixes(): Observable<any> {
    return this.referenceDataService.listCompanyPrefixes();
  }

  getCompanyPrefixByCode(code: string): Observable<any> {
    return this.referenceDataService.getCompanyPrefixByCode(code);
  }

  // sectors
  listSectors(): Observable<any> {
    return this.referenceDataService.listSectors();
  }

  // jointCommission
  getAllJointCommissions(): Observable<any> {
    return this.referenceDataService.getAllJointCommissions();
  }

  getJointCommissionsById(id: number): Observable<any> {
    return this.referenceDataService.getJointCommissionsById(id);
  }

  getJointCommissionsBySectorId(sectorId: number): Observable<any> {
    return this.referenceDataService.getJointCommissionsBySectorId(sectorId);
  }

  getPageJointCommission(index: number, size: number): Observable<any> {
      return this.referenceDataService.getPageJointCommission(index, size);
  }

  saveJointCommission(jointCommission: JointCommission): Observable<any> {
      return this.referenceDataService.saveJointCommission(jointCommission)
  }

  // prestationCode
  getPrestationCodeJustification(): Observable<any> {
    return this.referenceDataService.listPrestationCodeJustifications();
  }

  getPrestationCodesCorrections(): Observable<any> {
    return this.referenceDataService.listPrestationCodesCorrections();
  }

  savePrestationCode(prestationCode: PrestationCode): Observable<any> {
    return this.referenceDataService.savePrestationCode(prestationCode)
  }

  getPrestationCodes(): Observable<any> {
    return this.referenceDataService.listPrestationCodes();
  }

  getPagePrestationCode(index: number, size: number): Observable<any> {
    return this.referenceDataService.getPagePrestationCode(index, size);
  }

  updateJointCommissions(jointCommissionCode: string, active: boolean): Observable<any> {
    return this.referenceDataService.updateJointCommissions(jointCommissionCode, active);
  }

  // countries
  getCountries(): Observable<any> {
    return this.referenceDataService.listCountries();
  }

  // languages
  getLanguages(): Observable<any> {
    return this.referenceDataService.listLanguages();
  }

  // correctionTypes
  getAllCorrectionTypes(): Observable<any> {
    return this.referenceDataService.listCorrectionTypes();
  }

  // billingCoefficients
  getBillingCoefficientsByCustomerSize(customerSize: CustomerSize): Observable<any> {
    return this.referenceDataService.getBillingCoefficientsByCustomerSize(customerSize);
  }

  getPageBillingCoefficient(index: number, size: number): Observable<any> {
    return this.referenceDataService.getPageBillingCoefficient(index, size);
  }

  saveBillingCoefficient(billingCoefficients: BillingCoefficients): Observable<any> {
     return this.referenceDataService.saveBillingCoefficients(billingCoefficients)
  }

  // salaryScale
  getPageSalaryScale(index: number, size: number): Observable<any> {
    return this.referenceDataService.getPageSalaryScale(index, size);
  }

  saveSalaryScale(salaryScale: SalaryScale): Observable<any> {
     return this.referenceDataService.saveSalaryScale(salaryScale)
  }

  // travelExpenseCoefficient
  getPageTravelExpenseCoefficient(index: number, size: number): Observable<any> {
    return this.referenceDataService.getPageTravelExpenseCoefficient(index, size);
  }

  saveTravelExpenseCoefficient(travelExpenseCoefficient: TravelExpenseCoefficient): Observable<any> {
     return this.referenceDataService.saveTravelExpenseCoefficient(travelExpenseCoefficient)
  }

  // jointCommissionCoefficients
  getJointCommissionCoefficientsByJointCommissionCode(jointCommissionCode: string): Observable<any> {
    return this.referenceDataService.getJointCommissionCoefficientsByJointCommissionCode(jointCommissionCode);
  }

  getPageJointCommissionCoefficients(index: number, size: number): Observable<any> {
    return this.referenceDataService.getPageJointCommissionCoefficients(index, size);
  }

  saveJointCommissionCoefficients(jointCommissionCoefficient: JointCommissionCoefficientAdmin): Observable<any> {
    return this.referenceDataService.saveJointCommissionCoefficients(jointCommissionCoefficient);
 }

  // workFunctions
  getWorkFunctionById(workFunctionId: number): Observable<any> {
    return this.referenceDataService.getWorkFunctionById(workFunctionId);
  }

  listWorkFunctionsByJointCommissionCode(jointCommissionCode: string): Observable<any> {
    return this.referenceDataService.listWorkFunctionsByJointCommissionCode(jointCommissionCode);
  }

  getPageWorkFunction(index: number, size: number): Observable<any> {
    return this.referenceDataService.getPageWorkFunction(index, size);
  }

  saveWorkFunction(workFunction: WorkFunction): Observable<any> {
    return this.referenceDataService.saveWorkFunction(workFunction);
 }


  // workPositions
  getAllWorkerPositions(): Observable<any> {
    return this.referenceDataService.listWorkerPositions();
  }

  // nace
  getAllNaceCodes(): Observable<any> {
    return this.referenceDataService.listNaceCodes();
  }

  // bic
  getBicByIdentificationNumber(identificationNumber: string): Observable<any> {
    return this.referenceDataService.getBicByIdentificationNumber(identificationNumber);
  }

  // dimona
  getDimonaCodeNotisByCode(codeNotis: string): Observable<any> {
    return this.referenceDataService.getDimonaCodeNotisByCode(codeNotis);
  }

  // publicHoliday
  getPagePublicHoliday(index: number, size: number): Observable<any> {
    return this.referenceDataService.getPagePublicHoliday(index, size);
  }

  savePublicHoliday(publicHoliday: PublicHoliday): Observable<any> {
    return this.referenceDataService.savePublicHoliday(publicHoliday);
 }

  /*************************/
  /*         Dimona        */
  /*************************/
  private _dimonaService: DimonaService;

  private get dimonaService(): DimonaService {
    if (!this._dimonaService) {
      this._dimonaService = this.injector.get(DimonaService);
    }
    return this._dimonaService;
  }

  getDimonaToExport(startDate: Date, endDate: Date, operationUnitIds: Array<number>): Observable<any> {
    return this.dimonaService.export(startDate, endDate, operationUnitIds);
  }

  getDimonaByRequestId(requestId: number): Observable<any> {
    return this.dimonaService.getDimonaByRequestId(requestId);
  }

  submitDimona(dimonaPeriodId: string): Observable<any> {
    return this.dimonaService.submitDimona(dimonaPeriodId);
  }

  /*************************/
  /*        Document       */
  /*************************/
  private _documentService: DocumentService;

  private get documentService(): DocumentService {
    if (!this._documentService) {
      this._documentService = this.injector.get(DocumentService);
    }
    return this._documentService;
  }

  processDocumentByWorkerId(workerId: number, document: Document): Observable<any> {
    return this.documentService.processDocumentByWorkerId(workerId, document);
  }

  editWorkerDocumentFileType(workerId: number, oldFileType: TypeFileWorker | TypeFileCustomer, document: Document): Observable<any> {
    return this.documentService.editWorkerDocumentFileType(workerId, oldFileType, document);
  }

  uploadDocumentByWorkerId(workerId: number, document: Document, file: File): Observable<any> {
    return this.documentService.uploadDocumentByWorkerId(workerId, document, file);
  }

  deleteWorkerDocument(workerId: number, document: Document): Observable<any> {
    return this.documentService.deleteWorkerDocument(workerId, document);
  }

  downloadWorkerDocument(workerId: number, document: Document): Observable<any> {
    return this.documentService.downloadWorkerDocument(workerId, document);
  }

  getDocumentsByWorkerId(workerId: number, pending: boolean = false): Observable<any> {
    return this.documentService.getDocumentsByWorkerId(workerId, pending);
  }

  getDocumentsByCustomerId(customerId: number): Observable<any> {
    return this.documentService.getDocumentsByCustomerId(customerId);
  }

  editCustomerDocumentFileType(customerId: number, oldFileType: TypeFileWorker | TypeFileCustomer, document: Document): Observable<any> {
    return this.documentService.editCustomerDocumentFileType(customerId, oldFileType, document);
  }

  downloadCustomerDocument(customerId: number, document: Document): Observable<any> {
    return this.documentService.downloadCustomerDocument(customerId, document);
  }

  deleteCustomerDocument(workerId: number, document: Document): Observable<any> {
    return this.documentService.deleteCustomerDocument(workerId, document);
  }

  uploadDocumentByCustomerId(customerId: number, document: Document, file: File): Observable<any> {
    return this.documentService.uploadDocumentByCustomerId(customerId, document, file);
  }

  /*************************/
  /*        Invoice        */
  /*************************/
  private _invoiceService: InvoiceService;

  private get invoiceService(): InvoiceService {
    if (!this._invoiceService) {
      this._invoiceService = this.injector.get(InvoiceService);
    }
    return this._invoiceService;
  }

  getInvoiceById(invoiceId: number): Observable<any> {
    return this.invoiceService.get(invoiceId);
  }

  getInvoiceOriginalFileById(invoiceId: number): Observable<any> {
    return this.invoiceService.getOriginalFile(invoiceId);
  }

  getPageInvoice(index: number, size: number): Observable<any> {
    return this.invoiceService.getPage(index, size);
  }

  getPageBills(index: number, size: number, query: string ): Observable<any> {
    if (!query) {
      return this.invoiceService.getPage(index, size);
    }
    return this.elasticSearchService.filterBills(index, size, query);
  }

  generateWeeklyInvoices(): Observable<any> {
    return this.invoiceService.generateWeeklyInvoices();
  }

  generateMonthlyInvoices(): Observable<any> {
    return this.invoiceService.generateMonthlyInvoices();
  }

  getBillingToExport(prestationStartDateFrom: Date,
                     prestationStartDateTo: Date,
                     prestationEndDateFrom: Date,
                     prestationEndDateTo: Date,
                     invoiceCreationFrom: Date,
                     invoiceCreationTo: Date,
                     invoiceDueDateFrom: Date,
                     invoiceDueDateTo: Date,
                     operationUnitIds: Array<number>,
                     customerId: number): Observable<any> {
    return this.invoiceService.export(
      prestationStartDateFrom,
      prestationStartDateTo,
      prestationEndDateFrom,
      prestationEndDateTo,
      invoiceCreationFrom,
      invoiceCreationTo,
      invoiceDueDateFrom,
      invoiceDueDateTo,
      operationUnitIds,
      customerId);
  }

  getBillingToExportExactOnline(invoiceCreationFrom: Date,
                    invoiceCreationTo: Date,
                    operationUnitIds: Array<number>,
                    customerId: number): Observable<any> {
    return this.invoiceService.exportExactOnline(
      invoiceCreationFrom,
      invoiceCreationTo,
      operationUnitIds,
      customerId);
  }

  /*************************/
  /*        Planning       */
  /*************************/
  private _planningService: PlanningService;

  private get planningService(): PlanningService {
    if (!this._planningService) {
      this._planningService = this.injector.get(PlanningService);
    }
    return this._planningService;
  }

  planningByStartDateAndEndDate(customerIds: number[], operatingUnitIds: number[], startDate: Date, endDate: Date): Observable<any> {
    return this.planningService.getByStartDateAndEndDate(customerIds, operatingUnitIds, startDate, endDate);
  }

  /*************************/
  /*        Status         */
  /*************************/
  private _statusService: StatusService;

  private get statusService(): StatusService {
    if (!this._statusService) {
      this._statusService = this.injector.get(StatusService);
    }
    return this._statusService;
  }

  /*************************/
  /*    ElasticSearch      */
  /*************************/
  private _elasticSearchService: ElasticSearchService;

  private get elasticSearchService(): ElasticSearchService {
    if (!this._elasticSearchService) {
      this._elasticSearchService = this.injector.get(ElasticSearchService);
    }
    return this._elasticSearchService;
  }

  getRegistrationFormByWorkerId(workerId: number) {
    return this.registrationService.getRegistrationForm(workerId);
  }

  loadStatus(): Observable<any> {
    return this.statusService.list();
  }

  statusManualCheck(): Observable<any> {
    return this.statusService.manualCheck();
  }



  /*******************/
  /*    Utility      */
  /*******************/

  saveFile(result: Observable<any>) {
    result.subscribe({
      next: res => {
        const file = new Blob([res.body], {type: 'text/csv'});
        saveAs(file, res.headers.get('Content-Disposition').split('filename=')[1].replaceAll('\"', ''));
        this.openSnackBar(this.translate.instant('exports.message.downloadingSuccessfull'), 'X', 'customSnackValid');
      },
      error: error => {
        if (error.error instanceof Blob && error.error.type === 'application/json') {
          this.openSnackBar(this.translate.instant('exports.message.error.requestSize'), 'X', 'customSnackError');
        } else {
          this.openSnackBar(this.translate.instant('exports.message.error.export') + ' : ' + error.message, 'X', 'customSnackError');
        }
      }
    });
  }

  openSnackBar(message: string, action: string, pC: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }
}
