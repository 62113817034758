import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ExportInvoiceCreationDate {
  static invoiceCreationDateValidator(): ValidatorFn {
    return (form: UntypedFormGroup): { [key: string]: any } | null => {
      
      const dueDateFrom = form.get('invoiceCreationFrom')?.value;
      const dueDateTo = form.get('invoiceCreationTo')?.value;
      
      if (dueDateFrom && dueDateTo && dueDateTo < dueDateFrom) {
        
        return { dateRange: true } as ValidationErrors;
      }
  
      return null;
    };
  }
}

