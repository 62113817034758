import {PrestationPeriod} from './prestation-period.model';
import {JointCommissionCoefficient} from '../../../shared/model/joint-commission/joint-commission-coefficient.model';
import {Contract} from '../../../contracts/shared/model/contract.model';

export enum PrestationStatus {
  TO_VALIDATE = 'TO_VALIDATE',
  TO_VALIDATE_CORRECTION = 'TO_VALIDATE_CORRECTION',
  PARTIALLY_VALIDATED = 'PARTIALLY_VALIDATED',
  VALIDATED = 'VALIDATED',
  REJECTED = 'REJECTED',
  CORRECTED = 'CORRECTED',
  CANCELED = 'CANCELED'
}

export enum SocialSecretaryStatus {
  WAITING = 'WAITING',
  TO_RELEASE = 'TO_RELEASE',
  RELEASED = 'RELEASED'
}

export enum InvoicingStatus {
  WAITING = 'WAITING',
  TO_INVOICE = 'TO_INVOICE',
  INVOICED = 'INVOICED'
}


export class Prestation {
  id: number;
  jointCommissionCode: string;
  contractId: number;
  requestId: number;
  operatingUnitId: number;
  operatingUnitName: string;
  customerId: number;
  customerName: string;
  customerNumber: string;
  workerId: number;
  workerFirstName: string;
  workerLastName: string;
  workerNiss: string;
  workerFamilySituation: string;
  workerType: string;
  billingCoefficient: number;
  salaryScaleValue: number;
  travelExpenses: number;
  travelDistance: number;
  costCenter: string;
  jointCommissionCoefficient: JointCommissionCoefficient;
  status: PrestationStatus = PrestationStatus.VALIDATED;
  socialSecretaryStatus: SocialSecretaryStatus;
  invoicingStatus: InvoicingStatus;
  startDate: Date;
  endDate: Date;
  sentToSSDate: Date;
  periods: PrestationPeriod[] = [];
  active: boolean;
  travelCoefficient: number;
  partiallyProcessed?: boolean;
  fullyProcessed?: boolean;
  contract?: Contract;

  isValidate(): boolean {
    return [PrestationStatus.VALIDATED].includes(this.status);
  }

  isReleased(): boolean {
    return [SocialSecretaryStatus.RELEASED].includes(this.socialSecretaryStatus);
  }

  isInvoiced(): boolean {
    return [InvoicingStatus.INVOICED].includes(this.invoicingStatus);
  }

}
